import React from 'react';

import './index.css';

const HeroBody = () => {
  return (
    <section className="ai-machine-learning-hero-body-container">
      <p>
        There are few modern buzzwords as common as artificial intelligence. 
        According to Google, AI is “the theory and development of computer 
        systems able to perform tasks that normally require human intelligence, 
        such as visual perception, speech recognition, decision-making, and 
        translation between languages.”
        <br />
        <br />
        Your business may be able to find value from using technology to perform 
        human tasks, but it’s more likely that machine learning, a subcategory of 
        Artificial Intelligence, can offer more value.
        <br />
        <br />
        Machine learning uses incredible computational power to make sense of data 
        sets that are too large for humans to analyze. If your business has a wealth 
        of data (and in this modern age, what company doesn’t?) you might be able 
        to take advantage of machine learning, whether your data relates to customers, 
        transactions, transportation, or production. 
      </p>
    </section>
  );
};

export default HeroBody;
