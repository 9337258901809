import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

import './index.css';

// Sections
import Hero from '../../components/TechnologyHero';
import HeroBody from '../../components/aiMachineLearningSections/HeroBody';
import EllipseQuoteSection from '../../components/TopOrangeEllipseQuote';
import BestBlogs from '../../components/BestBlogs';
import FormSection from '../../components/Form';

const pageTitle =
  'AI Software Development & Machine Learning Services | Devetry';
const pageDescription =
  'Improve the efficiency of your organization with AI development and machine learning. Contact Devetry today.';

const AiMachineLearningPageTemplate = ({ blogs, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="ai-machine-learning-section root-container">
        <Hero
          title="AI & Machine Learning"
          description="Analyze, predict, and make better business decisions. "
          image={heroImage}
          body={<HeroBody />}
        />
        <EllipseQuoteSection
          title="Why Devetry"
          body={
            <span className='ai-ellipse-quote'>
              <p>
              If you plan to use AI or machine learning to replace an important 
              part of your business, you’ll want to make sure your development 
              team makes the right considerations when they write your code.
              <br />
              <br />
              At Devetry, we build algorithms and regularly check-in after 
              deployment to make sure your program is working correctly. 
              Are you getting what you need from your algorithm? Are there 
              instances of errors or unexpected outcomes? With machine learning, 
              it’s never deployed and done. Our team will adjust your algorithm 
              until it can make statistically significant discoveries. 
              <br />
              <br />
              There’s no such thing as true AI (yet) so we can’t yet rely on 
              machines to figure out their own biases or solve their own mistakes. 
              They’re only as smart and as capable as the people programming them. 
              Meet with our team to learn more about how we approach AI and machine 
              learning development
              </p>
            </span>
          }
        />
        <BestBlogs items={blogs} title="Refine your strategic skills" />
        <FormSection />
      </main>
    </>
  );
};

const AiMachineLearningPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const blogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));
  const heroImage = data.file;

  return <AiMachineLearningPageTemplate blogs={blogs} heroImage={heroImage}/>;
};

AiMachineLearningPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default AiMachineLearningPage;

export const pageQuery = graphql`
  query AiMachineLearningPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "AiMachineLearning/index" } }
    ) {
      frontmatter {
        templateKey
      }
    }
    allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Development" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
    file(relativePath: {
      eq: "team-working-computers2.jpg"
    }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
